import Box from "@mui/material/Box";
import Image from "next/image";
import { Background, Parallax } from "react-parallax";

import useBreakpoints from "@/hooks/useBreakpoints";

const Schedule = () => {
  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={300}>
      <Background className={"parallaxImage"}>
        <Image
          alt="Schedule parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/acapulco_mobile.webp"
              : "/vera_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"2vh"}
      >
        <div
          style={{
            borderRadius: "15px",
            display: "flex",
            background: "rgba(161, 140,94, 0.37)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(6.5px)",
            border: "1px solid rgba(161, 140, 94, 0.3)",
          }}
        >
          <Image
            src={
              tablet || laptop || desktop
                ? "/timeline.png"
                : "/timeline_vert.png"
            }
            alt="timeline"
            width={laptop || desktop ? 800 : tablet ? 700 : 380}
            height={tablet || laptop || desktop ? 400 : 800}
            quality={100}
            loading={"lazy"}
          />
        </div>
      </Box>
    </Parallax>
  );
};

export default Schedule;
